export const cmsNest = () => {
    const displayFetchError = (element, error) => {
        console.error("Error executing vdx-cmsnest-fetch code:", error);
        element.style.border = "1px red dotted";
        element.setAttribute("title", error.message);
        element.style.cursor = "help";
    };

    const getCacheKey = (url, selector) => `vdx-cmsnest-cache-${url}-${selector}`;

    const fetchAndReplaceContent = async (element) => {
        const url = element.getAttribute("href");
        const selector = element.getAttribute("vdx-cmsnest-fetch");
        const cacheKey = getCacheKey(url, selector);

        try {
            let content;
            if (localStorage.getItem(cacheKey)) {
                content = localStorage.getItem(cacheKey);
            } else {
                const response = await fetch(url);
                if (!response.ok) throw new Error(`Failed to fetch content from ${url}: ${response.statusText}`);
                content = await response.text();
                localStorage.setItem(cacheKey, content);
            }

            const doc = new DOMParser().parseFromString(content, "text/html");
            const selectedElement = selector ? doc.querySelector(`[vdx-cmsnest-select="${selector}"]`) : null;

            if (selectedElement && element.parentNode) {
                element.outerHTML = selectedElement.outerHTML;
                await reinitializeWebflow();
            } else {
                console.warn(`No matching element found in fetched HTML for selector [vdx-cmsnest-select="${selector}"]`);
            }
        } catch (error) {
            displayFetchError(element, error);
        }
    };

    const reinitializeWebflow = async () => {
        const cmsNestLinks = document.querySelectorAll("a[vdx-cmsnest-fetch]");
        if (cmsNestLinks.length !== 0) {
            for (let link of cmsNestLinks) {
                await fetchAndReplaceContent(link);
            }
            Webflow.destroy();
            Webflow.ready();
            Webflow.require("ix2").init();
        }
    };

    reinitializeWebflow();
};
