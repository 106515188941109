export const generateTOC = () => {
    const tocList = document.querySelector("[vdx-toc-list]");
    const tocArticle = document.querySelector("[vdx-toc-article]");

    if (!tocList || !tocArticle) return;

    const headings = tocArticle.querySelectorAll("h1, h2, h3, h4, h5, h6");
    if (headings.length === 0) return;

    ["h1", "h2", "h3", "h4", "h5", "h6"].forEach(tag => {
        let tocHeading = tocList.querySelector(`[vdx-toc-${tag}]`);
        if (tocHeading) {
            tocHeading.style.display = "none";
        }
    });

    headings.forEach((heading, index) => {
        const tagName = heading.tagName.toLowerCase();
        const tocHeading = tocList.querySelector(`[vdx-toc-${tagName}]`);
        if (tocHeading) {
            if (!heading.id) heading.id = "header-" + index;

            const listItem = document.createElement("li");
            if (tocHeading.className) listItem.className = tocHeading.className;

            const anchor = document.createElement("a");
            anchor.href = `#${heading.id}`;
            anchor.textContent = heading.textContent;

            const tocAnchor = tocHeading.querySelector("a");
            if (tocAnchor && tocAnchor.className) {
                anchor.className = tocAnchor.className;
            }

            const computedStyle = window.getComputedStyle(listItem);
            anchor.style.fontSize = computedStyle.fontSize;
            anchor.style.fontWeight = computedStyle.fontWeight;
            anchor.style.fontFamily = computedStyle.fontFamily;
            anchor.style.lineHeight = computedStyle.lineHeight;
            anchor.style.color = computedStyle.color;
            anchor.style.textDecoration = computedStyle.textDecoration;

            listItem.appendChild(anchor);
            tocList.appendChild(listItem);
        }
    });
};
