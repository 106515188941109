// countdown.js

export function countdown(targetDate) {
    const daysElement = document.querySelector("[vdx-countdown-days]");
    const hoursElement = document.querySelector("[vdx-countdown-hours]");
    const minsElement = document.querySelector("[vdx-countdown-mins]");
    const secsElement = document.querySelector("[vdx-countdown-secs]");

    function updateCountdown() {
        const now = new Date().getTime();
        const distance = targetDate - now;

        if (distance < 0) {
            clearInterval(countdownInterval);
            daysElement.textContent = "0";
            hoursElement.textContent = "0";
            minsElement.textContent = "0";
            secsElement.textContent = "0";
            return;
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        daysElement.textContent = days;
        hoursElement.textContent = hours;
        minsElement.textContent = minutes;
        secsElement.textContent = seconds;
    }

    const countdownInterval = setInterval(updateCountdown, 1000);
    updateCountdown();
}
