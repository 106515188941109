export const timedTabs = () => {
    const tabGroups = document.querySelectorAll("[vdx-tabs-timed]"); // Select all elements with the 'vdx-tabs-timed' attribute

    tabGroups.forEach(function(tabParent) {
        const tabs = tabParent.querySelectorAll("a"); // Assuming the links are inside the parent and are 'a' tags
        const tabCount = tabs.length;
        let currentIndex = 0;

        const switchInterval = parseInt(tabParent.getAttribute("vdx-tabs-timed")) || 3000; // Get the interval from the attribute, default to 3000ms if not set
        let intervalId;

        function switchTab() {
            tabs[currentIndex].click(); // Trigger a click on the current tab
            currentIndex = (currentIndex + 1) % tabCount; // Move to the next tab, loop back to the first after the last one
        }

        function startInterval() {
            clearInterval(intervalId); // Clear any existing interval
            intervalId = setInterval(switchTab, switchInterval); // Start a new interval
        }

        // Add event listeners to each tab link to reset the interval when clicked
        tabs.forEach(function(tab, index) {
            tab.addEventListener("click", function() {
                currentIndex = index; // Set currentIndex to the clicked tab
                startInterval(); // Restart the interval from the clicked tab
            });
        });

        startInterval(); // Start the interval when the function is called
    });
};
