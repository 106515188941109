import { handleReadTimeOnPageLoad, processReadTimeLink } from './readtime.js';
import { socialShare } from './socialshare.js';
import { formatDate } from './date.js';
import { currentTime } from './time.js';
import { onClick } from './onclick.js';
import { copyToClipboard } from './copyclip.js';
import { popupsIntent } from './popup.js';
import { cmsNest } from './cmsnest.js';
import { backButtons } from './backbutton.js';
import { generateTOC } from './toc.js';
import { transitionDelay } from './transitiondelay.js';
import { timedTabs } from './timedtabs.js';
import { showNavScroll } from './shownavscroll.js';
import { countdown } from './countdowntimer.js';



document.addEventListener("DOMContentLoaded", () => {
    // Initialize read time calculation
    document.querySelectorAll("[vdx-readtime-link]").forEach(processReadTimeLink);
    handleReadTimeOnPageLoad();

    socialShare();

    formatDate();

    currentTime();

    onClick();

    copyToClipboard();

    popupsIntent();

    cmsNest();

    backButtons();

    generateTOC();

    transitionDelay();

    timedTabs();

    showNavScroll();

    // Get the target date from the custom attribute
    const countdownElement = document.querySelector("[vdx-countdown-targetdate]");
    const targetDateAttr = countdownElement.getAttribute("vdx-countdown-targetdate");
    const targetDate = new Date(targetDateAttr).getTime();

    // Start the countdown
    countdown(targetDate);
    
});


console.log('VisualDXisGO');