export const popupsIntent = () => {
    const convertHoursToMilliseconds = (hours) => hours * 60 * 60 * 1000;

    const getLastShownTime = (key) => parseInt(localStorage.getItem(key)) || 0;

    const updateLastShownTime = (key) => localStorage.setItem(key, Date.now().toString());

    const shouldShowPopup = (key, frequency) => Date.now() - getLastShownTime(key) >= convertHoursToMilliseconds(frequency);

    const disableScroll = () => {
        document.body.style.overflow = "hidden";
    };

    const enableScroll = () => {
        document.body.style.overflow = "";
    };

    const hidePopup = (popup, key) => {
        popup.style.opacity = "0";
        setTimeout(() => {
            popup.style.display = "none";
            enableScroll();
            updateLastShownTime(key);
        }, 500);
    };

    const showPopup = (popup, key) => {
        popup.style.display = "block";
        disableScroll();
        setTimeout(() => {
            popup.style.opacity = "1";
        }, 10);
        updateLastShownTime(key);
    };

    const handleScrollDownPopup = () => {
        const popup = document.querySelector("[vdx-popup-scrolldown]");
        if (popup) {
            const scrollThreshold = parseInt(popup.getAttribute("vdx-popup-scrolldown")) || 50;
            const frequency = parseInt(popup.getAttribute("vdx-popup-frequency")) || 0;
            const storageKey = "popupOnScrollDownShown";

            if (shouldShowPopup(storageKey, frequency)) {
                const scrollListener = () => {
                    const scrollPercent = (window.scrollY + window.innerHeight) / document.documentElement.scrollHeight * 100;
                    if (scrollPercent >= scrollThreshold) {
                        showPopup(popup, storageKey);
                        window.removeEventListener("scroll", scrollListener);
                    }
                };
                popup.style.opacity = "0";
                popup.style.display = "none";
                window.addEventListener("scroll", scrollListener);
                popup.addEventListener("click", () => hidePopup(popup, storageKey));
            }
        }
    };

    const handleExitPopup = () => {
        const popup = document.querySelector("[vdx-popup-onexit]");
        if (popup) {
            const frequency = parseInt(popup.getAttribute("vdx-popup-frequency")) || 0;
            const storageKey = "popupOnExitShown";

            if (shouldShowPopup(storageKey, frequency)) {
                const exitListener = (event) => {
                    if (event.clientY <= 0) {
                        showPopup(popup, storageKey);
                        document.removeEventListener("mouseleave", exitListener);
                    }
                };
                popup.style.opacity = "0";
                popup.style.display = "none";
                document.addEventListener("mouseleave", exitListener);
                popup.addEventListener("click", () => hidePopup(popup, storageKey));
            }
        }
    };

    const handleScrollUpPopup = () => {
        const popup = document.querySelector("[vdx-popup-scrollup]");
        if (popup) {
            const scrollThreshold = parseInt(popup.getAttribute("vdx-popup-scrollup")) || 10;
            const frequency = parseInt(popup.getAttribute("vdx-popup-frequency")) || 0;
            const storageKey = "popupOnScrollUpShown";
            let lastScrollY = window.scrollY;

            if (shouldShowPopup(storageKey, frequency)) {
                const scrollListener = () => {
                    const currentScrollY = window.scrollY;
                    if (lastScrollY > currentScrollY && (lastScrollY - currentScrollY) / window.innerHeight * 100 >= scrollThreshold) {
                        showPopup(popup, storageKey);
                        window.removeEventListener("scroll", scrollListener);
                    }
                    lastScrollY = currentScrollY;
                };
                popup.style.opacity = "0";
                popup.style.display = "none";
                window.addEventListener("scroll", scrollListener);
                popup.addEventListener("click", () => hidePopup(popup, storageKey));
            }
        }
    };

    handleScrollDownPopup();
    handleExitPopup();
    handleScrollUpPopup();
};
